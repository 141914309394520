<template>
  <ListRow>
    <td>{{ rowData.templateTypeName }}</td>
    <td class="td_ellip" :data-ellip="rowData.evaluateName">
      <button
        class="link_subject"
        :data-ellip="rowData.evaluateName"
        @click="$windowOpen(toPathPartner)"
      >
        {{ rowData.evaluateName }}
      </button>
      <!-- <router-link
        :to="toPath"
        class="link_subject"
        :data-ellip="rowData.evaluateName"
        v-html="rowData.evaluateName"
      ></router-link> -->
    </td>
    <td>{{ rowData.stateCode }}</td>
    <td>{{ rowData.templateName }}</td>
    <td>{{ rowData.regId }}</td>
    <td>{{ rowData.totalScore }}</td>
    <td>{{ rowData.regDate | dateTimeStringFormatIfNullDash }}</td>
    <td class="td_ellip" :data-ellip="rowData.inspectNum">
      <button
        class="link_subject align_center"
        :data-ellip="rowData.inspectNum"
        @click="$windowOpen(toPathInspect)"
      >
        {{ rowData.inspectNum }}
      </button>
    </td>
    <td>
      <!--      {{ rowData.companyName }}-->
      <button
        class="link_subject align_center"
        @click="$windowOpen(toPathPartner)"
        v-html="rowData.companyName"
      />
    </td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import SelectBox from "@/components/common/selectBox/SelectBox";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "SystemPerformancePresentListLine",
  components: {
    ListRow,
    SelectBox,
  },
  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const { templateType, stateCode, userNum, inspectNum, evaluateNum } = this.rowData;
      if (templateType === "PE") {
        if (stateCode === "완료") {
          return makePathWithQuery(this.$routerPath.PERFORMANCE_VIEW, evaluateNum);
        } else {
          return makePathWithQuery(this.$routerPath.PERFORMANCE_WRITE, `${userNum}/${inspectNum}`);
        }
      } else {
        return makePathWithQuery(this.$routerPath.PARTNER_VIEW, userNum);
      }
    },
    toPathInspect() {
      return `${this.$routerPath.INSPECT_VIEW}/${this.rowData.inspectNum}`;
    },
    toPathPartner() {
      const { userNum } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.PARTNER_VIEW, userNum);
      return path;
    },
    onClickPerformanceResult() {
      this.$emit("onClickPerformanceResult", this.rowData);
    },
  },
};
</script>
