<template>
  <FilterBarBase @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl class="w152">
      <dt>구분</dt>
      <dd>
        <SelectBox
          class="w152"
          :dataList="templateType"
          :value.sync="statusId"
          @onChange="onChangeTemplateType"
        />
      </dd>
    </dl>
    <dl>
      <dt>평가일자</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w152" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <Input class="w336" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import FilterBarMixin from "@/mixins/FilterBarMixin";
import { dateToString } from "@/utils/dateUtils";

export default {
  name: "SystemPerformanceFilterBarPresent",
  components: {
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
  },
  mixins: [FilterBarMixin],
  props: {
    statusList: Array,
    defaultRadioId: String,
    defaultStatus: String,
    defaultDates: Array,
    defaultStateId: String,
    templateType: Array,
    defaultTemplateTypeId: String,
  },
  data() {
    return {
      dates: this.defaultDates,
      statusId: this.defaultTemplateTypeId,
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      this.$emit("onClickSearch", obj);
    },
    onClickReset() {
      this.onClickResetFilterBar();
      this.statusId = "";
      this.keywordSelectedId = "evaluateName";
    },
    onChangeTemplateType(val) {
      this.$emit("update:defaultTemplateTypeId", val);
    },
    getCommonFilterbarData() {
      const isValidDates = this.dates && this.dates.length === 2;
      const ret = {
        templateType: this.statusId,
        keywordSelectedId: this.keywordSelectedId,
        keywordInputText: this.keywordInputText.trim(),
        startDt: isValidDates ? dateToString(this.dates[0]) : null,
        endDt: isValidDates ? dateToString(this.dates[1]) : null,
      };

      return ret;
    },
  },
};
</script>
