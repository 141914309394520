<template>
  <Popup popupType="tableType" width="940px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">거래처 평가</h3>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="파트너 정보">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 267px" />
            <col style="width: 192px" />
            <col style="width: 267px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>업체명</th>
              <td>{{ model ? model.companyName : "" }}</td>
              <th>대표자</th>
              <td>{{ model ? model.ceo : "" }}</td>
            </tr>
            <tr>
              <th>평가일</th>
              <td>{{ model.regDate | dateStringFormat }}</td>
              <th>평가자</th>
              <td>{{ model ? model.regId : "" }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
        <template v-if="model.questList && model.questList.length">
          <TableViewWithTitle title="평가항목">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col style="width: 724px" />
            </template>
            <template v-slot:body>
              <tr v-for="(quest, qIdx) in model.questList" :key="`quest_${qIdx}`">
                <th>{{ qIdx + 1 }}</th>
                <td>
                  <p class="desc_view">{{ quest.question }}</p>
                  <template v-if="quest.optionList.length > 0">
                    <template v-if="quest.optionType === 'S'">
                      <PerformanceRadio
                        :name="`${qIdx}_${quest.optionList[0].optionNum}`"
                        :dataList="quest.optionList"
                        :selectedId.sync="quest.value"
                        :qIdx="qIdx"
                        :isDisabled="true"
                      />
                    </template>
                    <template v-else-if="quest.optionType === 'M'">
                      <Input
                        :value.sync="quest.value"
                        :maxLength="50"
                        placeholder="직접입력해주세요"
                        :isDisabled="true"
                      />
                    </template>
                  </template>
                  <template v-else>
                    <p class="desc_view">등록된 항목이 없습니다.</p>
                  </template>
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Radio from "@/components/common/radio/Radio";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Textarea from "@/components/common/textarea/Textarea";
import TableHead from "@/components/shared/TableHead";
import SelectBox from "@/components/common/selectBox/SelectBox";
import ApiService from "@/services/ApiService";
import PerformanceRadio from "@/components/admin/performance/write/PerformanceRadio";
import Input from "@/components/common/input/Input";
import PageMixin from "@/mixins/PageMixin";

export default {
  name: "PartnerEvaluateViewPopup",
  components: {
    Input,
    PerformanceRadio,
    SelectBox,
    Popup,
    TableViewWithTitle,
    Radio,
    InputAutoMoney,
    Textarea,
    TableHead,
  },
  mixins: [PageMixin],
  props: {
    model: Object,
    isEvaluated: Boolean,
    templateOption: Array,
    templateType: String,
  },
  data() {
    return {
      templateId: null,
      selectedScore: [],
      score: 0,
    };
  },
  computed: {},
  beforeMount() {
    this.templateId = this.model.evaluateData.templateId;
    this.getPerformanceTemplateData(this.model.evaluateData.templateId);
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    async getPerformanceTemplateData(id) {
      const path = `${this.$apiPath.ST_EVALUATE}/${id}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.model.templateData = {};
        return;
      }
      const { data } = result;
      this.model.setQuestList(data);
      this.model.templateData = data;

      if (data.questList && data.questList.length > 0) {
        this.selectedScore = new Array(data.questList.length);
      }
    },
  },
};
</script>
