<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">업체(평가)관리</h2>
      </div>
      <div class="body_section">
        <Tabs
          :keyParam="KEY_PARAM"
          :dataList="TAB_DATA_LIST"
          :currentValue.sync="currentTabValue"
          @onChangeTabValue="onChangeTabValue"
        />

        <SystemPerformanceFilterBarPresent
          :keywordDatas="KEYWORD_DATAS"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultDates="defaultDates"
          :templateType="TEMPLATE_TYPE"
          :defaultTemplateTypeId.sync="defaultTemplateTypeId"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount || 0"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 80px" />
            <col style="width: 200px" />
            <col style="width: 60px" />
            <col style="width: 200px" />
            <col style="width: 80px" />
            <col style="width: 60px" />
            <col style="width: 100px" />
            <col style="width: 100px" />
            <col style="width: 120px" />
          </template>
          <template v-slot:tr>
            <th>구분</th>
            <th>평가명</th>
            <th>진행상태</th>
            <th>템플릿명</th>
            <th>평가자</th>
            <th>평가점수</th>
            <th>평가완료일자</th>
            <th>검수번호</th>
            <th>상호</th>
          </template>
          <template v-slot:row="slotProps">
            <SystemPerformancePresentListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="9" class="td_empty">검색 결과가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
    <template v-slot:popup>
      <PartnerEvaluateViewPopup
        v-if="isPopPartnerEvaluateView"
        :model="evaluateData"
        @onClickClose="onClickClosePopPartnerEvaluateView"
      />

      <!-- 수행도평가 -->
      <PerformanceEvaluateResultPopup
        v-if="isPopPerformanceEvaluateResult"
        :evaluateResultData="evaluateResultData"
        @onClickClose="onClickClosePopPerformanceEvaluateResult"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Tabs from "@/components/common/tabs/Tabs";
import PerformanceFilterBarState from "@/components/admin/performance/list/PerformanceFilterBarState";
import PerformanceFilterBarResult from "@/components/admin/performance/list/PerformanceFilterBarResult";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import PerformanceListState from "@/components/admin/performance/list/PerformanceListState";
import PerformanceListResult from "@/components/admin/performance/list/PerformanceListResult";
import SystemPerformanceMixin from "@/components/admin/system/performance/SystemPerformanceMixin";
import ListPage from "@/components/shared/ListPage";
import ListPageMixin from "@/mixins/ListPageMixin";
import SystemPerformanceFilterBarPresent from "@/components/admin/system/performance/SystemPerformanceFilterBarPresent";
import { GET_EVALUATE_PROGRESS_LIST } from "@/store/modules/performance/action";
import SystemPerformancePresentListLine from "@/components/admin/system/performance/SystemPerformancePresentListLine";
import PartnerEvaluateViewPopup from "@/components/admin/partner/popup/PartnerEvaluateViewPopup";
import PerformanceEvaluateResultPopup from "@/components/shared/performanceEvaluate/PerformanceEvaluateResultPopup";

export default {
  name: "SystemPerformancePresentList",
  components: {
    SystemPerformancePresentListLine,
    SystemPerformanceFilterBarPresent,
    ListPage,
    PageWithLayout,
    Tabs,
    PerformanceFilterBarState,
    PerformanceFilterBarResult,
    TableHead,
    SelectBoxPageRange,
    PerformanceListState,
    PerformanceListResult,
    PartnerEvaluateViewPopup,
    PerformanceEvaluateResultPopup,
  },
  mixins: [SystemPerformanceMixin, ListPageMixin],
  data() {
    return {
      currentTabValue: "SYSTEM_PERFORMANCE_PRESENT",
      API_PATH: this.$apiPath.ST_EVALUATE_EVALUATE_SITUATE,
      defaultStateId: "",
      KEYWORD_DATAS: [
        { name: "평가명", code: "evaluateName", id: "evaluateName" },
        { name: "템플릿명", code: "templateName", id: "templateName" },
        { name: "평가자", code: "regId", id: "regId" },
        { name: "업체명", code: "companyName", id: "companyName" },
        { name: "검수번호", code: "inspectNum", id: "inspectNum" },
      ],
      defaultKeywordSelectedId: "evaluateName",
      TEMPLATE_TYPE: [
        { name: "전체", code: "" },
        { name: "파트너평가", code: "PA" },
        { name: "수행도평가", code: "PE" },
      ],
      defaultTemplateTypeId: "",
      isPopPartnerEvaluateView: false,
      isPopPerformanceEvaluateResult: false,
      evaluateResultData: {},
    };
  },
  computed: {},
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(GET_EVALUATE_PROGRESS_LIST).then(() => {
      this.getDataInit(params);
    });
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      const objDates = this.checkQueryDates(query, "startDate", "endDate");

      let obj = {};

      if (query.templateType) {
        obj.templateType = query.templateType;
        this.defaultTemplateTypeId = obj.templateType;
      }

      return Object.assign({}, objPaging, objKeyword, objDates, obj);
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, templateType } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }
      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }

      if (templateType) {
        params.templateType = templateType;
      }
      // console.log('param', params)

      return params;
    },
    onClickClosePopPerformanceEvaluateResult() {
      this.isPopPerformanceEvaluateResult = false;
    },
    onClickClosePopPartnerEvaluateView() {
      this.isPopPartnerEvaluateView = false;
    },
  },
};
</script>
