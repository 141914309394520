<template>
  <Popup popupType="tableType" width="980px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section group_form">
          업무수행도 평가<span class="bar_view" />
          <div class="txt_view">평가결과</div>
        </h3>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="거래정보">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 267px" />
            <col style="width: 192px" />
            <col style="width: 267px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>업체명</th>
              <td colspan="3">{{ evaluateResultData.companyName }}</td>
              <!--              <th>발주명</th>-->
              <!--              <td colspan="3">{{ evaluateResultData.model.title }}</td>-->
            </tr>
            <tr>
              <th>평가일</th>
              <td>{{ evaluateResultData.model.regDate | dateStringFormat }}</td>
              <th>평가자</th>
              <td>{{ evaluateResultData.model.regId }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <div class="section_comm">
      <div class="head_section">
        <h4 class="tit_section">평가 결과</h4>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 724px" />
          </template>
          <template v-slot:body>
            <tr v-for="(quest, qIdx) in evaluateResultData.model.questList" :key="`quest_${qIdx}`">
              <th>{{ qIdx + 1 }}</th>
              <td>
                <p class="desc_view">{{ quest.question }}</p>
                <template v-if="quest.optionList.length > 0">
                  <template v-if="quest.optionType === 'S'">
                    <PerformanceRadio
                      :name="`${qIdx}_${quest.optionList[0].optionNum}`"
                      :dataList="quest.optionList"
                      :selectedId.sync="quest.value"
                      :qIdx="qIdx"
                      :isDisabled="true"
                    />
                  </template>
                  <template v-else-if="quest.optionType === 'M'">
                    <Input :value.sync="quest.value" :maxLength="50" :isDisabled="true" />
                  </template>
                </template>
                <template v-else>
                  <p class="desc_view">등록된 항목이 없습니다.</p>
                </template>
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
        <TableViewWithTitle
          v-if="evaluateData.exEvaluateList && evaluateData.exEvaluateList.length > 0"
          title="평가내역"
        >
          <template v-slot:colgroup>
            <col style="width: 100px" />
            <col style="width: 120px" />
            <col style="width: 80px" />
            <col style="width: 616px" />
          </template>
          <template v-slot:header>
            <tr class="tr_eval">
              <th>평가일자</th>
              <th>평가자</th>
              <th>총점</th>
              <th>사유</th>
            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(item, idx) in evaluateData.exEvaluateList" :key="idx" class="tr_exeval">
              <td class="align_center">{{ item.regDate | dateStringFormat }}</td>
              <td class="align_center">{{ item.regId }}</td>
              <td class="align_center">{{ item.totalScore }}</td>
              <td class="align_left">{{ item.reason }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <div class="group_btn">
      <button type="submit" class="btn_primary btn_medium" @click="onClickClose">확인</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import TableHead from "@/components/shared/TableHead";
import PerformanceRadio from "@/components/admin/performance/write/PerformanceRadio";
import ApiService from "@/services/ApiService";

export default {
  name: "PerformanceEvaluateResultPopup",
  components: {
    PerformanceRadio,
    Popup,
    TableViewWithTitle,
    Input,
    TableHead,
  },
  props: {
    evaluateResultData: Object,
  },
  data() {
    return {
      evaluateNum: null,
      evaluateData: {},
    };
  },
  async beforeMount() {
    this.evaluateNum = this.evaluateResultData.model.evaluateNum;
    if (this.evaluateNum) {
      this.evaluateData = await this.getEvaluateData(this.evaluateNum);
    }
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    async getEvaluateData(evaluateNum) {
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}/${evaluateNum}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        return {};
      }

      return result.data;
    },
  },
};
</script>
